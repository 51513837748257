import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import Logo from 'src/components/ui/Logo'
import { Stripe } from 'src/components/global/Stripe'
import { useGlobalContext } from 'src/components/global/context/global-context'
import { useEffect, useState } from 'react'
import { NewIconHeartOutline } from 'src/components/Icons/NewIconHeartOutline'
import { BagCartOutline } from 'src/components/Icons/BagCartOutline'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { redirectToAccountOrLogin } from 'src/utils/redirectToAccountUrl'
import { useAccountContext } from 'src/components/account/context'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { useMobile } from 'src/hooks/useMobile'
import { Overlay } from 'src/components/ui/Overlay/Overlay'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { useScrollBlock } from 'src/utils/useScrollBlock'
import Cookies from 'js-cookie'

import { SearchInput } from '../SearchInput'
import { ButtonIcon } from './ButtonIcon'
import Menu from './Menu'
import LogoProMobile from '../../../images/decathlonProLogoMobile.svg'
import LogoProDesk from '../../../images/decathlonProLogoDesk.svg'
import { SidebarMenu } from './SideBarMenu'
import { LoginButton } from './LoginButton/LoginButton'

import './header.scss'

function Header() {
  const { isMobile } = useMobile()
  const [isHeaderFixed, setIsHeaderFixed] = useState(false)
  const [_, setIsScrolled] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)
  const { stripeSection } = useGlobalContext()
  const { itemsTotal } = useCheckout()
  const btnPropsCart = useCartToggleButton()
  const { isLoggedIn } = useAccountContext()
  const { blockScroll, allowScroll } = useScrollBlock()
  const standardObject = getStandardObject()
  const { pathname } = useLocation()

  const cartButtonAction = btnPropsCart

  const isB2B = checkEnviromentIsB2B()

  const [showLoginDropdown, setShowLoginDropdown] = useState(false)
  const [showCashbackMessage, setShowCashbackMessage] = useState(false)

  useEffect(() => {
    if (Cookies.get('cashback-message') === 'hide' || isLoggedIn) {
      return
    }

    setShowCashbackMessage(true)
    blockScroll()
  }, [blockScroll, isLoggedIn])

  useEffect(() => {
    if (!window || !document) {
      return
    }

    const handleScroll = () => {
      const currentScrollPos = document.documentElement.scrollTop

      // User is scrolling up
      setIsHeaderFixed(currentScrollPos < prevScrollPosition)

      setPrevScrollPosition(currentScrollPos)

      if (currentScrollPos !== prevScrollPosition) {
        setIsScrolled(true)
      }

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }

    window.addEventListener('scroll', handleScroll)
  }, [prevScrollPosition])

  const onClickLogin = () => {
    const redirectUrl = redirectToAccountOrLogin(isB2B, isLoggedIn, pathname)

    if (!isLoggedIn) {
      dispatchAmplitudeEvent({
        eventName: 'Account Process Entered',
        eventData: {
          ...standardObject,
        },
      })
    }

    window.location.href = redirectUrl
  }

  const handleCloseNotification = () => {
    Cookies.set('cashback-message', 'hide')
    setShowCashbackMessage(false)
    allowScroll()
  }

  const badgeConfig = {
    badgeValue: '$',
    badgeColor: 'mint', // #7AFFA6
    badgeTextColor: 'restructure-primary', // #101010
  }

  return (
    <>
      <Stripe stripeSection={stripeSection} />

      <header>
        <div
          className={`${
            isHeaderFixed && !showCashbackMessage && 'sticky top-0'
          } border-b header-container border-restructure-border-secondary bg-restructure-background-primary pt-sm pb-md restructure-small-desktop:pt-lg restructure-small-desktop:pb-0`}
        >
          {showCashbackMessage && <Overlay show />}

          <div className=" max-w-[1280px] w-full mx-auto px-md flex flex-col justify-between relative">
            <div className="flex flex-wrap items-center justify-between">
              <div className="restructure-small-desktop:hidden">
                <SidebarMenu
                  handleCloseNotification={handleCloseNotification}
                  showCashbackMessage={showCashbackMessage}
                  onClickLogin={onClickLogin}
                  badgeConfig={badgeConfig}
                />
              </div>
              {isB2B && (
                <Link
                  to="/"
                  className="w-[200px] restructure-small-desktop:w-[250px] flex items-center h-[24px] restructure-small-desktop:static restructure-small-desktop:top-0 restructure-small-desktop:left-0 restructure-small-desktop:translate-x-[0] absolute top-[7px] left-[50%] translate-x-[-50%]"
                  aria-label="Go to Decathlon home"
                  title="Go to Decathlon home"
                >
                  <img
                    className="w-full object-contain"
                    src={isMobile ? LogoProMobile : LogoProDesk}
                    alt="decathlon pro logo"
                  />
                </Link>
              )}
              {!isB2B && (
                <Link
                  to="/"
                  className="flex items-center h-[1.625rem] restructure-small-desktop:static restructure-small-desktop:top-0 restructure-small-desktop:left-0 restructure-small-desktop:translate-x-[0] absolute top-[7px] left-[50%] translate-x-[-50%]"
                  aria-label="Go to Decathlon home"
                  title="Go to Decathlon home"
                >
                  <Logo width={186} height={28} bgFill="#3643BA" />
                  <h1 className="sr-only">Decathlon</h1>
                </Link>
              )}

              <div className="order-4 mt-sm restructure-small-desktop:mt-0 restructure-small-desktop:order-1 w-full restructure-small-desktop:max-w-[623px]">
                <SearchInput />
              </div>

              <ul className="relative items-center order-2  restructure-small-desktop:flex">
                {!isB2B && (
                  <li className="hidden restructure-small-desktop:flex">
                    <ButtonIcon link="/account/wishlist">
                      <NewIconHeartOutline
                        aria-label="Botao para redirecionar para a wishlist"
                        data-testid="wish-list-button-icon"
                        className="w-6 h-6 restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
                      />
                    </ButtonIcon>
                  </li>
                )}

                <li
                  onMouseEnter={() => setShowLoginDropdown(true)}
                  onMouseLeave={() => setShowLoginDropdown(false)}
                  className={`${
                    showCashbackMessage && 'relative z-[10000000]'
                  } hidden restructure-small-desktop:flex`}
                >
                  <LoginButton
                    showLoginDropdown={showLoginDropdown && !isB2B}
                    showCashbackMessage={showCashbackMessage}
                    handleCloseNotification={handleCloseNotification}
                    onClick={onClickLogin}
                    badgeConfig={badgeConfig}
                  />
                </li>

                <li>
                  <ButtonIcon badgeValue={itemsTotal} {...cartButtonAction}>
                    <BagCartOutline
                      aria-label="botão abrir mini cart"
                      className="w-6 h-6 restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
                    />
                  </ButtonIcon>
                </li>
              </ul>
            </div>
            <Menu />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
